/**@jsxImportSource @emotion/react */

import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "../../hooks";

import CustomDivTable from "../../components/custom-table/custom-div-table";

import { cssSystemHistory as tempCss } from "../systems/css";
import { useTheme } from "@emotion/react";
import moment from "moment";

export default function SessionTableAllSessions(props) {
  const theme = useTheme()
  const css = tempCss(theme)?.systemHistory

  const [activeData, setActiveData] = useState(null)

  const { data: systemData, refetch } = useQuery(`/system/${props?.systemId}/data-all-sessions`, { refetchInterval: 15000 });
  console.log("System Data: ", systemData);

  useEffect(() => {
    if (systemData) {
      setActiveData(systemData)
    }
  }, [systemData])

  const cleanField = (text) => {
    return text ? text : "0"
  }
  
  //const data = activeData
  const detail_columns = [
    {
      name: "session_id",
      default: () => "",
      valFn: (data) => `${ cleanField(data?.session_id) }`,
      display: "Session Id",
    },
    {
      name: "started_at",
      default: () => "",
      valFn: (data) => `${ cleanField(moment(data?._min.updated_at).format('MM/DD/YYYY hh:mm:ssa')) }`,
      display: "Start Time",
    },
    {
      name: "updated_at",
      default: () => "",
      valFn: (data) => `${ cleanField(moment(data?._max.updated_at).format('MM/DD/YYYY hh:mm:ssa')) }`,
      display: "End",
    },
    {
      name: "count",
      default: () => "",
      valFn: (data) => `${ cleanField(data?._sum.count) }`,
      display: "Count",
    },
    {
      name: "total_weight",
      default: () => "",
      valFn: (data) => `${ cleanField(data?._sum.total_weight?.toFixed(2)) }`,
      display: "Weight",
    },
    {
      name: "diseased",
      default: () => "",
      valFn: (data) => `${ cleanField(data?._sum.diseased) }`,
      display: "Defective",
    }
    
  ];

  // title section
  const titleSection = (
    <div css={css?.mostRecentSession}>
      <div>All Sessions</div>
    </div>
  )

  return (
    <>
    <br />
      {titleSection}
      
      <CustomDivTable
        columns={detail_columns}
        rows={activeData?.data}
        // selectionMode="single"
        // onSelect={(it) => handleToUser(it)}
        //filterKeys={["first_name", "last_name", "email"]}
        showSearch={false}
      />

  </>

  );
}