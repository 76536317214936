/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";

import {
  Button,
  FormElement
} from "../../components";

import { css } from "./css";

const ResetPassword = ({
  toLogin = () => {},
  handlePasswordReset = () => {},
}) => {
  const theme = useTheme();

  return (
    <div css={[css?.formBlock(theme), css?.formBlock(theme)?.reset]}>
      <div css={css?.formBlock(theme)?.intro}>
        <h1>Reset your password</h1>
        <p>If you have forgotten your password you can reset it here.</p>
      </div>

      <div css={css?.formBlock(theme)?.elements}>
        <FormElement
          element="input"
          elementType="text"
          id="email"
          name="email"
          label="Email"
        />

        <Button label="Send My Password" onClick={handlePasswordReset} />
    
        <p css={css.account(theme)}>
          <span onClick={toLogin}>Back to Log in</span>
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
