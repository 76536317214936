import {
  borderRadius,
  // darken,
  fontbox,
  paddingX,
  paddingY,
  marginY,
  transition,
} from "../../emotion/utils";

import { appFonts } from "../../emotion/_appFonts";
import { appColors } from "../../emotion/_appColors";

export const css = (theme, color) => ({
  container: {
    ...borderRadius(50),
    ...fontbox(appFonts.inter, 12, 500, 12),
    ...paddingX(10),
    ...paddingY(4),
    ...marginY(4),
    ...transition(),
    // width: "87px",
    backgroundColor:
     theme?.button?.[color]?.background || theme?.button?.primary?.background,
    border: `1px solid ${
      theme?.button?.[color]?.border || theme?.button?.primary?.border
    }`,
    color: theme?.button?.[color]?.color || theme?.button?.primary?.color,
    cursor: "pointer",
    whiteSpace: "nowrap",

    '@media screen and (min-width: 576px)': {
      ...fontbox(appFonts.inter, 14, 500, 14),
    },

    "&:hover": {
      // backgroundColor: darken(theme?.button?.[color]?.background, -0.05),
    },

    '&.active': {
              backgroundColor: appColors.blues.pillactivebg,
              border:`1px solid ${ appColors.blues.pillborderactive}`,
              color: appColors.blues.pillactive,
            },

    link: {
      ...borderRadius(0),
      ...paddingX(0),
      ...paddingY(0),
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "unset",
      color: theme?.button?.[color]?.border || theme?.button?.primary?.border,
      flex: "unset",
      marginRight: "auto",
      width: "auto",

      "&:hover": {
        backgroundColor: "transparent",
        // color: darken(theme.button[color].border, -0.25),
      },
    },
  },
});
