import {
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  paddingXY,
  square,
} from '../../emotion/utils'

import { appColors } from '../../emotion/_appColors'
import { appFonts } from '../../emotion/_appFonts'

export const css = {
  ...flexbox('row', 'flex-start', 'space-between', 12, 'wrap'),
  ...fontbox(appFonts?.inter, 14, 500, 14 * 1.44),
  ...fullWidth(),
  ...paddingXY(24),
  backgroundColor: appColors?.grays?.[0],
  boxShadow: '0px 4px 16px 0px rgba(1, 32, 78, 0.08)',
  color: appColors?.grays?.[900],
  maxWidth: 518,

  info: {
    l: {
      ...flexbox('column', 'flex-start', 'flex-start', 8),
      flex: 1,

      "> div": {
        ...flexbox("row", "flex-start", "flex-start", 8),

        "span": {
          color: appColors?.grays?.[500],
          minWidth: 94,
        },
      },
    },

    withLabel: {
      ...flexbox("row", "flex-start", "flex-start", 0),
    },

    nameTitle: {
      ...flexbox('column', 'flex-start', 'flex-start', 8),
      ...fullWidth(),
    },

    name: {
      ...fontbox(appFonts?.inter, 20, 700, 20 * 1.44),
    },

    title: {
      ...fontbox(appFonts?.inter, 16, 400, 16 * 1.44),
      textTransform: 'capitalize',
    },

    notes: {
      ...fullWidth(),

      '> div': {
        ...fontbox(appFonts?.inter, 16, 700, 16 * 1.44),
      },
    },

    r: {
      width: 96,

      img: {
        ...borderRadius('50%'),
        ...square(96),
        objectFit: 'cover',
      },
    },
  },

  defaultAvatar: {
    ...borderRadius('50%'),
    ...flexbox('column', 'center', 'center'),
    ...square(96),
    backgroundColor: appColors?.grays?.[50],
  },
}
