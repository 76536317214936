import {
  flexbox,
  fontbox,
  borderRadius,
  fullWidth,
  square,
  posAbsolute,
  posRelative,
  paddingX,
} from '../../emotion/utils'

import { appColors } from '../../emotion/_appColors'
import { appFonts } from '../../emotion/_appFonts'

export const css = theme => ({
  videocard: {
    ...borderRadius(8),
    ...flexbox('column', 'center', 'center'),
    ...fullWidth(),
    ...posRelative(),
    backgroundColor: appColors?.grays?.[50],
    borderRadius: 8,
    border: `1px solid ${appColors?.grays?.[100]}`,
    overflow: 'hidden',

    '& p': {
      ...borderRadius(4),
      ...fontbox(appFonts.inter, 12, 500, 20),
      ...paddingX(4),
      ...posAbsolute(null, 5, 5, null, 10),
      backgroundColor: `${appColors.grays[1]}50`,
      color: appColors.grays[0],
      letterSpacing: '0.15em',
      textAlign: 'center',
    },

    '> div': {
      ...borderRadius(7),
      height: 'auto',
    },

    loading: {
      ...flexbox('column', 'center', 'center'),
      minHeight: 300,

      svg: {
        ...square(120),
      },
    },
  },
})
