export const appColors = {
  grays: {
    0: "#FFFFFF",
    // 50: "#F6F6F6",
    100: "#E5E5E5",
    // 100: "#E6E6E6",
    // 200: "#CCCCCC",
    // 300: "#B3B3B3",
    // 400: "#727678",
    // 500: "#808080",
    // 510: "#838383",
    // 600: "#666666",
    // 700: "#4D4D4D",
    740: "#424242",
    // 800: "#333333",
    1: "#000000",

    // border: "#959B9D",
  },

  blues: {
    //   base: "#3142AF",
    //   primary: {
    //     200: '#81C9EE'
    //   },
    //   tertiary: {
    //     600: "#475467"
    //   },
    //   pill: "#F9FAFB",
    flashWhite: "#F2F4F7",
    solitude: "#EAECF0",
    eastBay: "#475467",
    blackRussian: "#101828",
    //   pillactive: "#2D576B",
    //   pillactivebg: "#D7EBF9",
    //   pillborderactive: "#59A3C5",
  },

  coral: {
    50: "#FFF7F4",
    300: "#FAC7B7",
    600: "#F0744D",
  },

  reds: {
    //   base: "#D52C51",
    //   secondary: "#CF223B",
    //   destructive: "#911426",
    chileanFire: "#D5542A",
    hdOrange: "#D92D20",
  },

  greens: {
    //   base: "#193542",
    //   secondary: "#2B7B7B",
    //   navbuttonactivebg: "#07161D",
    //   hightight: "rgba(189, 252, 253,  0.05)",
    //   textlight: "#BDFCFD",
    jade: "#17B26A"
  },
};
