import {
  flexbox,
  fontbox,
  fullWidth,
  marginAuto,
  posAbsolute,
  posRelative,
} from '../../emotion/utils'

import { appFonts } from '../../emotion/_appFonts'

export const css = {
  container: (theme) => ({
    backgroundColor: theme?.background?.primary,
    minHeight: '100vh',
    paddingTop: 65,

    "&::before": {
      ...posAbsolute(162, 0, 0, 0, 1),
      backgroundColor: theme?.background?.gray?.[100],
      content: '""',
    },

    "&::after": {
      ...posAbsolute(null, 0, 0, 0, 2),
      backgroundColor: theme?.background?.utility?.[400],
      content: '""',
      height: 35,

      '@media screen and (min-width: 576px)': {
        height: 54,
      },
    },
  }),

  block: {
    ...marginAuto(),
    maxWidth: 360,
    width: '80%',

    '> form': {
      ...flexbox('column', 'center', 'flex-start', 65),
      ...posRelative(),
      zIndex: 10,

      '> *': {
        ...fullWidth(),
        textAlign: 'center',
      },

      "> div": {
        "&:first-of-type": {
          width: "auto"
        },
      },

      "> svg": {
        width: 150,

        "@media screen and (min-width: 576px)": {
          ...fullWidth()
        }
      }
    },
  },

  formBlock: theme => ({
    ...flexbox('column', 'center', 'flex-start', 24),
    paddingTop: 32,
    color: theme?.text?.grays?.[1],

    '> *': {
      ...fullWidth(),
    },

    intro: theme => ({
      ...flexbox('column', 'center', 'center', 12),

      h1: {
        ...fontbox(appFonts.inter, 20, 600, 28),
        color: theme?.text?.primary,

        '@media screen and (min-width: 576px)': {
          ...fontbox(appFonts.inter, 30, 600, 38),
        },
      },
    }),

    elements: {
      ...flexbox("column", "center", "flex-start", 20),

      "> *": {
        ...fullWidth()
      },
    },
  }),
}
