import {
  flexbox,
  fontbox,
  fullWidth,
  marginAuto,
  posAbsolute,
  posRelative,
  transition,
} from "../../emotion/utils";

import { appFonts } from "../../emotion/_appFonts";

export const css = {
  container: (theme) => ({
    backgroundColor: theme?.background?.primary,
    minHeight: "100vh",
    paddingTop: 65,

    "&::before": {
      ...posAbsolute(162, 0, 0, 0, 1),
      backgroundColor: theme?.background?.gray?.[100],
      content: '""',
    },

    "&::after": {
      ...posAbsolute(null, 0, 0, 0, 2),
      backgroundColor: theme?.background?.utility?.[400],
      content: '""',
      height: 35,

      '@media screen and (min-width: 576px)': {
        height: 54,
      },
    },
  }),
  
  block: {
    ...marginAuto(),
    maxWidth: 360,
    width: "80%",

    "> form": {
      ...flexbox("column", "center", "center", 65),
      ...posRelative(),
      zIndex: 10,

      "> *": {
        ...fullWidth(),
        textAlign: "center",
      },

      "> div": {
        "&:first-of-type": {
          width: "auto"
        },
      },

      "> svg": {
        width: 150,

        "@media screen and (min-width: 576px)": {
          ...fullWidth()
        }
      }
    },
  },

  formBlock: (theme) => ({
    ...flexbox("column", "center", "flex-start", 24),
    paddingTop: 32,
    color: theme?.text?.grays?.[1],

    "> *": {
      ...fullWidth(),
    },

    reset: {
      ...posAbsolute(96, 0, null, 0, 1),
    },

    intro: (theme) => ({
      ...flexbox("column", "center", "center", 12),

      h1: {
        ...fontbox(appFonts?.inter, 20, 600, 28),
        color: theme?.text?.primary,

        "@media screen and (min-width: 576px)": {
          ...fontbox(appFonts?.inter, 30, 600, 38),
        },
      },

      p: {
        ...fontbox(appFonts?.inter, 12, 400, 20),
        color: theme?.text?.tertiary,

        "@media screen and (min-width: 576px)": {
          ...fontbox(appFonts?.inter, 16, 400, 24),
        },
      },
    }),

    forgotlink: (theme) => ({
      ...flexbox("column", "start", "center", 12),

      "> span": {
        ...fontbox(appFonts?.inter, 12, 600, 20),
        color: theme?.text?.primary,

        "@media screen and (min-width: 576px)": {
          ...fontbox(appFonts?.inter, 14, 600, 24),
        },

        "&:hover": {
          // color: darken(theme?.button?.primary?.background, -0.25),
          cursor: "pointer",
        },
      },
    }),

    elements: {
      ...flexbox("column", "center", "flex-start", 20),

      "> *": {
        ...fullWidth(),
      },
    },
  }),

  // errorBox: (theme) => ({
  //   ...borderRadius(12),
  //   ...flexbox("column", "center", "space-between", 55),
  //   ...posAbsolute(null, -12, -12, -12, 1),
  //   backgroundColor: theme?.background?.primary,
  //   border: `2px solid ${theme?.button?.danger?.background}`,
  //   boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.25)",
  //   minHeight: 300,
  //   padding: 10,
  //   width: "calc(100% + 20px)!important",

  //   msg: {
  //     ...flexbox("column", "center", "center", 8),

  //     h2: {
  //       ...fontbox(appFonts?.inter, 30, 600, 38),
  //     },

  //     p: {
  //       ...fontbox(appFonts?.inter, 16, 400, 24),
  //       color: theme?.text?.primary,

  //       "@media (min-width: 768px)": {
  //         ...fontbox(appFonts?.inter, 20, 400, 28),
  //       },
  //     },
  //   },

  //   btn: {
  //     ...fullWidth(),
  //   },
  // }),

  account: (theme) => ({
    ...fontbox(appFonts?.inter, 12, 400, 20),
    // color: themes?.text?.tertiary,

    "@media screen and (min-width: 576px)": {
      ...fontbox(appFonts?.inter, 14, 400, 20),
    },

    "> span": {
      ...fontbox(appFonts?.inter, 12, 600, 20),
      ...transition(),
      // color: theme?.button?.primary?.background,
      cursor: "pointer",

      "@media screen and (min-width: 576px)": {
        ...fontbox(appFonts?.inter, 14, 600, 20),
      },
    },
  }),

  signup: (theme) => ({
    ...fontbox(appFonts?.inter, 14, 400, 20),

    "> span": {
      ...fontbox(appFonts?.inter, 14, 700, 20),
      // color: theme?.text?.primary,

      "@media screen and (min-width: 576px)": {
        ...fontbox(appFonts?.inter, 14, 700, 24),
      },
      "&:hover": {
        // color: darken(theme?.button?.primary?.background, -0.25),
        cursor: "pointer",
      },
    },
  }),
};
