/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";

import { Button } from "../index";

import { css } from "./css";

const ErrorBox = ({
  header = "Error!",
  copy = "Wrong Email or Password",
  btnLabel = "Try Again",
  btnColor = "danger",
  onClick = () => {}
}) => {
  const theme = useTheme();

  const errorBtnProps = {
    label: btnLabel,
    color: btnColor,
    onClick: onClick,
    theme: theme,
    css: css(theme).btn
  };

  const msg = (
    <div css={css(theme).msg}>
      <h2>{header}</h2>
      <p>{copy}</p>
    </div>
  );

  const errorBtn = <Button {...errorBtnProps} />;

  return (
    <div css={css(theme)}>
      {msg}
      {errorBtn}
    </div>
  )
};

export default ErrorBox;
