/** @jsxImportSource @emotion/react */

import { Password as PasswordPR } from "primereact/password";

import { cssFormElements } from "../css";

const Password = (data) => {
  const getLabel = data?.label && <label>{data.label}</label>;

  const allProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
  };

  const elProps = {
    // Component based off of PrimeReact Password component
    // https://primereact.org/password/

    // required props for PrimeReact Password component
    value: allProps?.value,
    onChange: allProps?.onChange,

    // optional props
    // https://primereact.org/password/

    // custom props
    id: allProps?.id,
    name: allProps?.name,
    onBlur: allProps?.onBlur,
  };

  const getElement = <PasswordPR {...elProps} feedback={false} />;

  return (
    <div css={cssFormElements}>
      {getLabel}
      {getElement}
    </div>
  );
};

export default Password;
