import { appFonts } from "../../emotion/_appFonts";
import {
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  posAbsolute,
} from "../../emotion/utils";

export const css = theme => ({
  ...borderRadius(12),
  ...flexbox("column", "center", "space-between", 55),
  ...posAbsolute(null, -12, -12, -12, 1),
  backgroundColor: theme?.background?.primary,
  border: `2px solid ${theme?.button?.danger?.background}`,
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.25)",
  minHeight: 300,
  padding: 10,
  width: "calc(100% + 20px)!important",

  msg: {
    ...flexbox("column", "center", "center", 8),

    h2: {
      ...fontbox(appFonts?.inter, 30, 600, 38),
    },

    p: {
      ...fontbox(appFonts?.inter, 16, 400, 24),
      color: theme?.text?.primary,

      "@media (min-width: 768px)": {
        ...fontbox(appFonts?.inter, 20, 400, 28),
      },
    },
  },

  btn: {
    ...fullWidth(),
  },
});
