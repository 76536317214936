/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState, useRef, useMemo } from "react";
import CustomDivTable from "../../components/custom-table/custom-div-table";
import { useMutation } from '@tanstack/react-query';
//import ShowAddNewUserModal from "./show-add-new-user-modal";
//import ShowArchiveUserModal from "./show-archive-user-modal";
import { useQuery } from "../../hooks";
import { Toast } from "primereact/toast";
import NavigationActionBar from "../../components/navigation-action-bar/navigation-action-bar";
import IconGo from "../../components/icons/icon-go";
import { Button } from "antd";
import AddUserModal from "../../components/modals/AddUserModal";
import EditUserModal from "../../components/modals/EditUserModal";
import CustomDivTableActions from "../../components/custom-table/custom-div-table-actions";
import ButtonIcon from "../../components/button-icon/button-icon";
import Icon from "../../components/icon/icon";
import { appColors } from "../../emotion/_appColors";
import { css } from "./css";
import { useNavigate } from "react-router-dom";
import ShowArchiveUserModal from "./show-archive-user-modal";
import Client, { ENDPOINT } from '../../client';

export default function Users() {
  const toastRef = useRef();
  const [showUserModal, setShowUserModal] = useState(false);

  const [archiveUserModal, setArchiveUserModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);

  const activeUser = useRef(null);

  const navigate = useNavigate();

  const theme = useTheme();

  const { data, refetch } = useQuery("/users?archived=false");

  const { mutate: resendInvite } = useMutation({
    mutationFn: (userId) => Client.post(`/users/${userId}/resend-invite`, {}),
    onSuccess: () => {
      console.log('invite resent')
    }
  })

  const onEdit = (data) => {
    activeUser.current = data;
    setShowEditUserModal(true);
  };

  const onDelete = (data) => {
    activeUser.current = data;
    setArchiveUserModal(true);
  };

  const handleToUser = (data) => {
    activeUser.current = data;
    navigate(`/admin/user/${data.id}`);
  }
  
  const columns = [
    {
      name: "name",
      default: () => "",
      valFn: (data) => `${data.first_name ? data.first_name + ' ' + data.last_name : "--"}`,
      display: "Name",
    },
    // {
    //   name: "role",
    //   default: () => "",
    //   valFn: (data) => `${data.role_name ? capitalizeFirstLetter(data.role_name) : "--"}`,
    //   display: "Role",
    // },
    // {
    //   name: "system_num",
    //   default: () => "",
    //   valFn: (data) => `${data.systems?.length ? data.systems.map(s => s.name).join(', ') : "--"}`,
    //   display: "System Number(s)",
    // },
    {
      name: "email",
      default: () => "",
      valFn: (data) => `${data.email ? data.email : "--"}`,
      display: "Email",
    },
    {
      name: "actions",
      default: () => "",
      valFn: (data) => {
        const inviteBtn = !data.invitation_accepted
          ? (
            <ButtonIcon
              icon={<span>resend invite</span>}
              onClick={() => resendInvite(data.id)}
            />
          )
          : null;

        return (
          <CustomDivTableActions>
            {inviteBtn}
            <ButtonIcon
              icon={<Icon icon="Trash" stroke={null} fill={appColors?.grays?.[510]} />}
              // onClick={() => onDelete(data)}
              onClick={() => onDelete(data)}
            />
            <ButtonIcon
              icon={<Icon icon="Edit" stroke={null} fill={appColors?.grays?.[510]} />}
              onClick={() => onEdit(data)}
            />
            <ButtonIcon
              icon={<Icon icon="ChevronRight" stroke={null} fill={appColors?.grays?.[510]} />}
              onClick={() => handleToUser(data)}
            />
          </CustomDivTableActions>
        );
      },
    },
  ];

  const modals = (
    <>
      {archiveUserModal && (
        <ShowArchiveUserModal
          setShowModal={setArchiveUserModal}
          user={activeUser.current}
          onSuccess={refetch}
        />
      )}
      {showAddUserModal && (
        <AddUserModal
          setShowModal={setShowAddUserModal}
          user={activeUser.current}
          onSuccess={refetch}
        />
      )}
      
      {showEditUserModal && (
        <EditUserModal
          setShowModal={setShowEditUserModal}
          user={activeUser.current}
          onSuccess={refetch}
        />
      )}
    </>
  );

  return (
    <div css={css}>
      <Toast ref={toastRef} position="top-center" />
      <NavigationActionBar refetch={refetch} />

      <CustomDivTable
        columns={columns}
        rows={data?.data}
        // selectionMode="single"
        // onSelect={(it) => handleToUser(it)}
        filterKeys={["first_name", "last_name", "email"]}
        showSearch={false}
      />

      {modals}
    </div>
  );
}