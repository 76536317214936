/** @jsxImportSource @emotion/react */
import {useEffect, useState} from "react"
import { useTheme } from '@emotion/react';
import { cssSystemSummary as tempCss } from '../systems/css'

import moment from 'moment'

export default function SystemSummary({
    system,
    showImage
  }) {

    const activeData = system
    
    const theme = useTheme()
    const css = tempCss(theme, showImage)?.systemSummary

    console.log("system: ", activeData?.id, '\nsummary', activeData?.summary);
    const summary = activeData?.summary;

    var lastRun = summary?.last_run
    var lastUpdate = summary?.last_update

    if (lastRun !== "Unknown") {
      lastRun = moment(summary?.last_run).format('MMMM Do YYYY | h:mm:ss a');
      lastUpdate = moment(summary?.last_update).format('MMMM Do YYYY | h:mm:ss a');
    }

  const location = summary?.location;
  const lastCount = summary?.last_count;
  const weight = summary?.weight //?.toFixed(2);
  const diseased = summary?.diseased;

  const data = [
    {
      attribute: 'Location',
      value: location
    },
    {
      attribute: 'System',
      value: system?.name ?? "Unknown"
    },
    {
      attribute: 'Last Session Start',
      value: lastRun
    },
    {
      attribute: 'Last Update',
      value: lastUpdate
    },
    {
      attribute: 'Total Count',
      value: lastCount
    },
    
    {
      attribute: 'Total Weight (lbs)',
      value: weight
    },
    {
      attribute: 'Defective',
      value: diseased
    },
  ];

  return (
    <div css={css} className="container">
      {data.map(({ attribute, value }) => (
        <div key={attribute}>
          <span className="systemAttribute">{attribute}</span>
          <span className="systemValue">{value}</span>
        </div>
      ))}
    </div>
  );
}