/** @jsxImportSource @emotion/react */

// import {useMemo, useEffect, useState} from "react"
import { useTheme } from "@emotion/react";
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import moment from "moment";

import { Icon } from "../../components";

import SystemSummary from "./system-card-summary";

import { cssSystemCard as tempCss } from "../systems/css";
import { useNavigate } from "react-router-dom";

const SystemCard = (props) => {
  const { data, setActiveSystem, isActive, id, showImage = true } = props;

  const activeData = data
  const navigate = useNavigate()

  const theme = useTheme();
  const css = tempCss(theme)?.systemCard(isActive);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const systemState = activeData?.summary?.state ?? "OFF"
  var systemStatus = ( // JER: need to get the status somehow
    <>
      <div css={css?.header?.statusDotOn} />
      <span>On</span>
    </>
  );

  if (systemState === "OFF") {
    systemStatus = ( // JER: need to get the status somehow
      <>
        <div css={css?.header?.statusDotOff} />
        <span>Off</span>
      </>
    );
  }

  const headerBlock = (
    <div css={css.header}>
      <h1>{activeData?.name}</h1>
      <div>{ systemStatus }</div> 
    </div>
  );

  const horseImg = activeData?.image;

  const imgBlock = (
    <div css={css?.imgBlock}>
      {horseImg
        ? <img src={horseImg?.link} alt={data?.name || ""} />
        : (
          <div css={css?.imgBlock?.plc}>
            <Icon icon="CameraFeedDefault" /> 
          </div>
        )
      }
    </div>
  );

  const imgDate = /*data?.image?.created_at && */(
    <div css={css?.img?.created}>
      {moment(activeData?.image?.created_at).format('MM/DD/YYYY HH:MM')}
    </div>
  );


  const onClickSystem = () => {
    // setActiveSystem(data)
    navigate(`/admin/system/${activeData?.id}`)
  };

  return (
    <div
      id={id}
      css={css}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      <div css={css.body} onClick={onClickSystem}>
        { headerBlock }
        {showImage && (
          <>
            {imgDate}
            {imgBlock}
          </>
        )}
        <SystemSummary system={activeData} showImage={showImage} />
      </div>
    </div>
  );
};
export default SystemCard;