/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react'
import { useNavigate, useParams } from 'react-router-dom'
import { setState, useEffect, useState } from 'react'

import SystemHistory from './session-history'

import {
  Button,
  CameraSelectorComponent,
  Icon,
  VideoComponent,
} from '../../components'

import { css as tempCss } from './css'
import { useQuery } from '../../hooks'
import SystemCard from './system-card'
import { selectedReloadState } from '../../store/noteSlice'

export default function System() {
  const [activeSystem, setActiveSystem] = useState(null)

  // DATA
  const { id: systemId } = useParams()
  const { data: systemData, refetchSystem } = useQuery(`/system/${systemId}`, { refetchInterval: 15000 })
  console.log('System Load:', systemData)

  useEffect(() => {
    if (systemData) {
      setActiveSystem(systemData)
    }
      
  }, [systemData])
  
  const [activeCamera, setActiveCamera] = useState(null)
  const [fullScreen, setFullScreen] = useState(false)

  useEffect(() => {
    if(activeSystem?.camera?.length > 0) 
      setActiveCamera(activeSystem?.camera[0])
  }, [activeSystem])

  const navigate = useNavigate()
  const theme = useTheme()
  const css = tempCss(theme)

  const actionBlock = (
    <div css={css?.actionBlock}>
      <div css={css?.actionBlock?.back} onClick={() => navigate('/admin/systems')}>
        <Icon icon="ChevronLeft" />
        Dashboard
      </div>
    </div>
  )

  const videoBlock = (
    <div css={css.videoBlock} className="videoContentArea">  
      <VideoComponent 
          currentCamera={activeCamera} 
          full_screen={fullScreen} 
          />
      <CameraSelectorComponent 
          activeCamera={activeCamera} 
          setActiveCamera={setActiveCamera} 
          activeSystem={activeSystem} 
          refetch={refetchSystem}
          />
    </div>
  )

  const currentStatusBlock = (
    <div css={css?.statusBlock}>
      <SystemCard
        data={systemData}
        id={systemId}
        setActiveSystem={null}
        showImage={false}
      />
    </div>
  )

  return (
    <div css={css}>
      {actionBlock}

      <div css={css?.content}>
        {activeCamera && videoBlock}
        {currentStatusBlock}
      </div>

      <SystemHistory systemId={systemId} />
    </div>
  )
}
