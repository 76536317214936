/**@jsxImportSource @emotion/react */

import React, { useState, useRef, useEffect } from "react";
//import CustomDivTable from "../../components/custom-table/custom-div-table";

import { useQuery } from "../../hooks";

import { cssSystemHistory as tempCss } from "../systems/css";
import { useTheme } from "@emotion/react";
//import moment from "moment";

import SystemChart from "./session-chart";
import SessionTableLast from "./session-table-last"
import SessionTableAllRows from "./session-table-allrows"
import SessionTableAllSessions from "./session-table-allsessions"

export default function SystemHistory(props) {
  const theme = useTheme()
  const css = tempCss(theme)?.systemHistory

  const [activeData, setActiveData] = useState(null)

  console.log("History system id: ", props?.systemId);

  const { data: systemData, refetch } = useQuery(`/system/${props?.systemId}/data`, { refetchInterval: 15000 });
  console.log("System Data: ", systemData);

  useEffect(() => {
    if (systemData) {
      setActiveData(systemData)
    }
  }, [systemData])


  // title section
  const recent = activeData?.aggregates[0];
  const titleSection = (
    <div css={css?.mostRecentSession}>
      <div>Most Recent Session</div>
      <div>Session ID: { recent?.session_id }</div>
      {/* <div>Started At: { formattedDate }</div> */}
    </div>
  )

  return (
    <div css={css}>
      {titleSection}

      <SystemChart 
        data={activeData} 
        />

      <SessionTableLast
        data={activeData}
      />

      {/* <SessionTableAllRows
        data={activeData}
      /> */}

      <SessionTableAllSessions 
        systemId={props?.systemId}
        />

    </div>
  );
}