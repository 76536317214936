/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo } from 'react'
import { Tag } from 'antd'

// import { useQuery } from '../../hooks/use-query'
import { useAppDispatch, useAppSelector } from '../../shared/model'
import { ENDPOINT } from '../../client'
import {Droppable} from '../droppable'
import {Draggable} from '../draggable'
import {SortableContext} from '@dnd-kit/sortable'

import PillButton from '../pill-button/pill-button'
import SystemSettingsModal from '../modals/SystemSettingsModal'
import Client from '../../client'
import Icon from '../icon/icon'

import { css } from './css';
import { Dropdown } from 'primereact/dropdown'

export default function CameraSelectorComponent(props) {
  const {
    activeCamera,
    setActiveCamera,
    activeSystem,
    refetch
  } = props
  const [activeCameraId, setActiveCameraId] = useState(null)
  const [cameras, setCameras] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { cameraData } = props

  const handleCogClick = () => {
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const cameraOptions = activeSystem?.camera.map((item, key) => {
    const name = (activeSystem.default_camera_id || 0) === item.id ? "*" + item.name : item.name
    return {
      value: item.id,
      label: name
    }  
  })

  const setDefaultCamera = async (id) => {
    if(id !== activeSystem.default_camera_id) {
      console.log("Setting default camera")
      await Client.put(`/system/${activeSystem.id}`, {updated_system: {default_camera_id:id}})
      refetch()
    }
  }
  const camerasList = activeSystem?.camera.map((item, key) => (
      <PillButton
        key={key}
        onClick={() => setActiveCamera(item)}
        className={activeCamera?.id === item?.id ? 'active' : ''}
      >
        {(activeSystem.default_camera_id || 0) === item.id ? "*" + item.name : item.name}
      </PillButton>
  ));

  // commented out the settings button
  const settingsBtn = (
    <div css={css.settings} onClick={handleCogClick}>
      <Icon icon="Settings" stroke="#475467" />
    </div>
  );

  const cameraArea = (
    <div css={css.cameraArea} className="camera-area">
      {camerasList}
    </div>
  );

  const modals = (
    <>
      {isModalOpen && (
        <SystemSettingsModal
          open={isModalOpen}
          setShowModal={setIsModalOpen}
          onClose={handleModalClose}
        />
      )}
    </>
  );

  return (
    
      <Droppable >
        {cameraArea}
        {modals}
      </Droppable>
    
  )
}
