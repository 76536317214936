/** @jsxImportSource @emotion/react */

import React, { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import Modal from "react-bootstrap/Modal";
import Client from "../../client";
import CustomButton from "../../components/custom-button/custom-button";
import { useMutation } from "@tanstack/react-query";
import { Dialog } from "primereact/dialog";

import { css } from "../../components/modals/css";
import ModalButtonSet from "../../components/modal-set-button/modal-set-button";
import { appColors } from "../../emotion/_appColors";

export default function ShowArchiveUserModal(props) {
  const {
    setShowModal = () => {},
    user,
    onAccept = () => {},
  } = props;

  const toastRef = useRef();

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: "remove-member",
    networkMode: "always",
    mutationFn: async () =>
      Client.patch(`/users/${user?.id}`, { archive: !user?.archived_at }),
    onSuccess: () => {
      setShowModal(false);
      onAccept();
    },
    onError: (error) =>
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: error.toString(),
        life: 3000,
      }),
  });

  const handleCancel = () => {
    setShowModal(false)
  }

  return (
    <Dialog
      header={
        <span css={css.dialogHeader} className="dialog-header">
          {user?.archive ? "Reactivate" : "Remove"} user {user?.data?.name}
        </span>
      }
      visible={true}
      style={{ width: 400 }}
      onHide={handleCancel}
    >
      <p> Are you sure you want to {user?.archive ? "reactivate" : "remove"} member <i>{user?.data?.name}</i>?</p>
      <ModalButtonSet
        onCancel={handleCancel}
        isSubmitting={isLoading}
        onSubmit={submitRequest}
        submitButtonText={user?.archive ? "Reactivate" : "Remove"}
        submitButtonColor={appColors?.reds?.destructive}
        disabled={isLoading}
      />
    </Dialog>
  );
}