/**@jsxImportSource @emotion/react */

import React from 'react'
import { css } from './css'
import Button from '../../components/button/button'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '../../hooks'
import UserCard from '../../components/user-card/user-card'

export default function ViewUser() {
  const navigate = useNavigate()

  // DATA
  const { id: userId } = useParams()

  const { data: userData } = useQuery(`/users/${userId}`)

  // const { data: horsesData } = useQuery(`/users/${userId}/horses`);
  const { data: horsesData } = useQuery(`/horses/`)

  const handleClick = () => {
    navigate('/admin/users')
  }

  const btnBack = (
    <Button css={css.btnBack} onClick={() => handleClick()}>
      Back to list
    </Button>
  )

  const actions = [btnBack].map((btn, index) => (
    <React.Fragment key={index}>{btn}</React.Fragment>
  ))

  const getUserCard = <UserCard data={userData} />

  // const getHorses = userId => {
  //   let filteredHorses = []

  //   horsesData?.data?.map(row => {
  //     if (row?.creatorId === userId) {
  //       filteredHorses.push({
  //         ...row,
  //         name: row.name,
  //         id: row.id,
  //       })
  //     }
  //   })

  //   return (
  //     <>
  //       {filteredHorses.map((horse, index) => (
  //         <UserCard key={index} data={horse} horse />
  //       ))}
  //     </>
  //   )
  // }

  const content = (
    <div css={css.content}>
      {getUserCard}
      {/* {getHorses(userId)} */}
    </div>
  )

  return (
    <div css={css}>
      {actions}
      {content}
    </div>
  )
}
