/**@jsxImportSource @emotion/react */

//import React, { useState, useRef, useEffect } from "react";
import CustomDivTable from "../../components/custom-table/custom-div-table";

import { cssSystemHistory as tempCss } from "../systems/css";
import { useTheme } from "@emotion/react";


export default function SessionTableLast(props) {

  const activeData = props?.data

  const theme = useTheme()
  const css = tempCss(theme)?.systemHistory

  const cleanField = (text) => {
    return text ? text : "0"
  }
  
  //const data = activeData
  const columns = [
    {
      name: "size",
      default: () => "",
      valFn: (data) => `${ cleanField(data?.size) }`,
      display: "Size",
    },
    {
      name: "count",
      default: () => "",
      valFn: (data) => `${ cleanField(data?._sum?.count) }`,
      display: "Count",
    },
    {
      name: "total_weight",
      default: () => "",
      valFn: (data) => `${ cleanField(data?._sum?.total_weight?.toFixed(2)) }`,
      display: "Weight",
    },
    {
      name: "diseased",
      default: () => "",
      valFn: (data) => `${ cleanField(data?._sum?.diseased) }`,
      display: "Defective",
    }
  ];


  return (

      <CustomDivTable
        columns={columns}
        rows={activeData?.aggregates}
        // selectionMode="single"
        // onSelect={(it) => handleToUser(it)}
        //filterKeys={["first_name", "last_name", "email"]}
        showSearch={false}
      />

  );
}