import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { noteSlice } from "./noteSlice";
import { systemSlice } from "./systemSlice";
import { horseSlice } from "./horseSlice";
import { horseListSlice } from './horseListSlice';
import { userListSlice } from './userListSlice';
import { clipSlice } from './clipSlice';
import { barnSetupSlice } from './barnSetupSlice';
import { viewerSlice } from './viewerSlice';
import { rootReducer } from './rootReducer';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: [horseSlice.name, horseListSlice.name, userListSlice.name, barnSetupSlice.name, clipSlice.name, noteSlice.name, viewerSlice.name, systemSlice.name],
};

export function makeStore() {
  const store = configureStore({
    // 👇 ATTENTION: persistReducer broke infering RootState
    reducer: persistReducer(
      persistConfig,
      rootReducer,
    ),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
  return store;
}

export const appStore = makeStore();
export const persistor = persistStore(appStore);