// import { keyframes } from '@emotion/react'
import {
  borderBottomLeftRadius,
  borderRadius,
  borderTopRightRadius,
  flexbox,
  fontbox,
  fullHeight,
  fullWidth,
  gridbox,
  marginAuto,
  paddingX,
  paddingXY,
  paddingY,
  posAbsolute,
  posRelative,
  square,
  transition,
} from '../../emotion/utils';

import { appColors } from '../../emotion/_appColors';
import { appFonts } from '../../emotion/_appFonts';

// const fadeOutAndMove = keyframes`
//   0% {
//     opacity: 0;
//     margin-right: 0;
//   }
//   50% {
//     opacity: 0;
//     //margin-right: 600px;
//   }
//   100% {
//     opacity: 1;
//     //margin-right: 600px;
//   }
// `

export const css = {
  systems: {
    ...fullWidth(),
    ...marginAuto(),

    // '@media screen and (min-width: 768px)': {
    //   width: '97%',
    // },
  },

  cardSection: theme => ({
    ...gridbox(1, 24),
    backgroundColor: theme?.background?.primary,
    
    '@media screen and (min-width: 1100px)': {
      ...gridbox(2, 24),
    },
  }),

  tray: theme => ({
    ...fullHeight(),
    ...fullWidth(),
    // ...paddingX('3%'),
    ...transition('transform 0.3s ease-in-out'),
    backgroundColor: theme?.background?.primary,
    // paddingTop: 68,
    // paddingBottom: 32,
    // transform: "translateX(100%)",

    // '@media screen and (min-width: 420px)': {
    //   paddingTop: 58,
    // },
    // '@media screen and (min-width: 768px)': {
    //   ...paddingX(32),
    //   paddingTop: 96,
    // },

    // "&.open": {
    //   // transform: "translateX(0)",
    //   zIndex: 50,
    // },

  }),

  closeButton: {
    ...borderBottomLeftRadius(4),
    ...flexbox("column", "center", "center"),
    ...posAbsolute(60, 0, null, null, 100),
    ...square(50),
    background: appColors?.reds?.destructive,
    border: 'none',
    cursor: 'pointer',
    zIndex: 1000,
  },

  // systemCardSection: {
  //   background: appColors.grays[0], 
  //   padding: '16px 16px 40px',
  //   height: '90vh',
  //   border: '1px solid #E6E9EA',
  //   borderRadius: '8px',
  //   overflowY: 'auto',
  //   overflowX: 'hidden',
  // },
  container: {
    ...flexbox("column", "flex-start", "flex-start", 8),
    background: appColors.grays[0], 
    padding: '16px 16px 40px',
    height: '90vh',
    border: '1px solid #E6E9EA',
    borderRadius: '8px',
    overflowY: 'auto',
    overflowX: 'hidden',

    "> *": {
      ...fullWidth(),
    },
  },
  // },

  cardHeader: theme => ({
    ...flexbox('row', 'center', 'space-between', 12),
    ...fullWidth(),

    h1: {
      ...fontbox(appFonts.inter, 14, 700, 14),
      color: appColors.grays[900],
    },
    p: {
      ...fontbox(appFonts.inter, 14, 400, 14),
      color: appColors.grays[900],
    },
  }),

  systemCardHeader: {
    ...flexbox('row', 'center', 'space-between', 12),
    ...paddingY(16),
    // padding: '24px 0px 24px 0px',

    h1: {
      ...fontbox(appFonts.inter, 14, 700, 14),
      color: appColors.grays[900],
    },

    p: {
      ...fontbox(appFonts.inter, 14, 400, 14),
      color: appColors.grays[900],
    },
  },

  systemNoteSection: {
    ...flexbox('column', 'start', 'space-between', 12),
    padding: '0px 0px 8px 0px',
    width: '100%',

    h3: {
      ...fontbox(appFonts.inter, 14, 700, 14),
      color: appColors.grays[900],
    },
    p: {
      ...fontbox(appFonts.inter, 14, 400, 14),
      color: appColors.grays[900],
    },
  },
  
  systemNotes: theme => ({
    width: '100%',
  }),
  systemNoteEntry: theme => ({
    width: '100%',
  }),

  systemAttribute: theme => ({
    display: "inline-block" ,
  })
}

export const cssSystemCard = theme => ({
  systemCard: isActive => ({
    ...borderRadius(12),
    ...flexbox("column", "flex-start", "flex-start", 16),
    ...fullHeight(),
    ...posRelative(),
    border: `1px solid ${isActive ? theme?.border?.coral?.[300] : theme?.border?.coral?.[300]}`,
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    overflow: 'hidden',

    imgBlock: {
      ...fullWidth(),

      '@media screen and (min-width: 420px)': {
        maxWidth: 180
      },
      '@media screen and (min-width: 576px)': {
        maxWidth: 230
      },
      '@media screen and (min-width: 768px)': {
        maxWidth: 280,
      },
      '@media screen and (min-width: 1100px)': {
        maxWidth: 230,
      },
      '@media screen and (min-width: 1200px)': {
        maxWidth: 280,
      },

      plc: {
        // ...fullHeight(),
        // width: 280,
        width: 'inherit',

        '> div': {
          width: 'inherit',

          '> svg': {
            ...fullWidth(),
            height: 'auto',
          },
        },

        '> img': {
          ...borderRadius(0),
        }
      },
    },

    img: {
      ...borderRadius(4),
      display: "block",
      width: '100%',
      height: 'auto',

      created: {
        ...fontbox(appFonts?.inter, 10, 600, 10 * 1.5),
        ...paddingX(8),
        ...paddingY(4),
        ...posAbsolute(35, 0, null, null, 10),
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: theme?.text?.grays?.[0],

        '@media screen and (min-width: 420px)': {
          ...borderTopRightRadius(6),
          ...posAbsolute('auto', 'auto', 0, 0, 10),
        },
      },
    },

    body: {
      ...flexbox('row', 'stretch', 'flex-start', 0, 'wrap'),
      ...fullWidth(),
      flex: 1,

      '> div': {
        '&:last-of-type': {
          // flex: 2,
        },
      },
    },

    header: {
      ...flexbox('row', 'center', 'space-between', 12),
      ...fullWidth(),
      ...paddingX(12),
      ...paddingY(6),
      backgroundColor: theme?.background?.coral?.[50],
      borderBottom: `1px solid ${theme?.border?.secondary}`,
      flex: 'unset',

      '@media screen and (min-width: 1100px)': {
        ...paddingX(24),
        ...paddingY(12),
      },

      h1: {
        ...fontbox(appFonts?.inter, 12, 600, 12 * 1.5),
        color: theme?.text?.primary?.[900],

        '@media screen and (min-width: 768px)': {
          ...fontbox(appFonts?.inter, 14, 600, 14 * 1.5),
        },
        '@media screen and (min-width: 992px)': {
          ...fontbox(appFonts?.inter, 16, 600, 16 * 1.5),
        },
      },

      '> div': {
        ...flexbox('row', 'center', 'flex-end', 4),

        '@media screen and (min-width: 768px)': {
          gap: 8,
        },

        'span': {
          ...fontbox(appFonts?.inter, 12, 600, 12 * 1.5),
          color: theme?.text?.primary?.[600],

          '@media screen and (min-width: 768px)': {
            ...fontbox(appFonts?.inter, 14, 600, 20),
          },
        },
      },

      statusDotOn: {
        ...borderRadius(4),
        ...square(8),
        backgroundColor: appColors?.greens?.jade,
      },
      statusDotOff: {
        ...borderRadius(4),
        ...square(8),
        backgroundColor: appColors?.reds?.chileanFire,
      },
    },
  }),
})

export const cssSystemSummary = (theme, showImage) => ({
  systemSummary: {
    ...flexbox('column', 'stretch', 'space-evenly',0),
    ...fullWidth(),

    '@media screen and (min-width: 420px)': {
      flex: 1,
      width: 'auto',
    },

    '> div': {
      ...flexbox('column', 'flex-start', 'flex-start', 4),
      ...paddingX(12),
      ...paddingY(6),
      borderBottom: `1px solid ${theme?.border?.secondary}`,

      '@media screen and (min-width: 300px)': {
        ...flexbox('row', 'center', 'space-between', 4),
        ...paddingX(6),
        ...paddingY(3),
      },
      '@media screen and (min-width: 768px)': {
        ...paddingX(showImage ? 24 : 12),
      },
      '@media screen and (min-width: 1100px)': {
        ...paddingX(12),
      },
      '@media screen and (min-width: 1300px)': {
        ...paddingX(24),
      },

      '&:last-of-type': {
        borderBottom: 'none',
      },

      '> span': {
        ...fontbox(appFonts?.inter, 10, 500, 14),
        color: theme?.text?.primary?.[900],
        flex: 1,

        '@media screen and (min-width: 420px)': {
          flex: 'unset',
        },
        '@media screen and (min-width: 576px)': {
          ...fontbox(appFonts?.inter, 14, 500, 18),
        },
        '@media screen and (min-width: 768px)': {
          ...fontbox(appFonts?.inter, 14, 500, 20),
            flex: 1,
        },
        '@media screen and (min-width: 1100px)': {
          ...fontbox(appFonts?.inter, 12, 500, 18),
        },
        '@media screen and (min-width: 1200px)': {
          ...fontbox(appFonts?.inter, 14, 500, 20),
        },
        
        '&:last-of-type': {
          ...fontbox(appFonts?.inter, 12, 500, 16),
          color: theme?.text?.primary?.[600],

          '@media screen and (min-width: 420px)': {
            ...fontbox(appFonts?.inter, 10, 400, 14),
          },
          '@media screen and (min-width: 576px)': {
            ...fontbox(appFonts?.inter, 14, 400, 18),
          },
          '@media screen and (min-width: 768px)': {
            ...fontbox(appFonts?.inter, 14, 400, 20),
          },
          '@media screen and (min-width: 1100px)': {
            ...fontbox(appFonts?.inter, 12, 400, 18),
          },
          '@media screen and (min-width: 1200px)': {
            ...fontbox(appFonts?.inter, 14, 400, 20),
          },
        },
      },
    },
  },
})

export const cssSystemTray = theme => ({
  systemTray: {
    ...flexbox('column', 'flex-start', 'flex-start', 12),
    paddingBottom: 32,

    '@media screen and (min-width: 768px)': {
      gap: 24,
    },
    
    '> *': {
      ...fullWidth(),
    },

    actionBlock: {
      ...flexbox('row', 'center', 'space-between', 12),

      '> div': {
        cursor: 'pointer',
      },

      back: {
        ...flexbox('row', 'center', 'flex-start', 6),
        ...fontbox(appFonts?.inter, 12, 600, 20),
        color: theme?.text?.primary?.[600],

        '@media screen and (min-width: 768px)': {
          ...fontbox(appFonts?.inter, 14, 600, 20),
        },

        '&:hover': {
          color: theme?.text?.utility?.[400],

          'svg': {
            'path': {
              stroke: theme?.text?.utility?.[400],
            }
          }
        },
      },

      expand: {
        ...square(20),

        '&:hover': {
          'svg': {
            'path': {
              fill: theme?.text?.utility?.[400],
            }
          }
        },
      },
    },

    content: {
      ...flexbox('column', 'flex-start', 'flex-start', 12),
      ...fullWidth(),

      '@media screen and (min-width: 768px)': {
        ...flexbox('row', 'stretch', 'flex-start', 24),
      },
    },
    
    videoBlock: {
      ...flexbox("column", "flex-start", "flex-start", 8),
      ...fullWidth(),

      '@media screen and (min-width: 768px)': {
        maxWidth: 350,
      },
      '@media screen and (min-width: 992px)': {
        maxWidth: 460,
      },
      '@media screen and (min-width: 1100px)': {
        maxWidth: 610,
      },
    },

    statusBlock: {
      ...fullWidth(),
      flex: 1,

      '> div': {
        '> div': {
          ...flexbox('column', 'flex-start', 'flex-start', 0),

          '> *': {
            ...fullWidth(),
          },
        }
      },
    },
  },
})

export const cssSystemHistory = theme => ({
  systemHistory: {
    ...flexbox('column', 'flex-start', 'flex-start', 12),

    '@media screen and (min-width: 768px)': {
      gap: 24,
    },

    '> *': {
      ...fullWidth(),
    },

    mostRecentSession: {
      ...borderRadius(12),
      ...flexbox('column', 'flex-start', 'flex-start', 0),
      ...fontbox(appFonts?.inter, 12, 400, 18),
      backgroundColor: `${theme?.border?.coral?.[300]}75`,
      border: `1px solid ${theme?.border?.coral?.[300]}`,
      overflow: 'hidden',

      '@media screen and (min-width: 768px)': {
        ...flexbox('row', 'center', 'flex-start', 0),
        ...fontbox(appFonts?.inter, 14, 400, 20),
      },

      '> *': {
        ...fullWidth(),

        '@media screen and (min-width: 768px)': {
          width: 'auto',
        },
      },

      '> div': {
        ...paddingX(8),
        ...paddingY(4),
        backgroundColor: theme?.background?.primary,

        '@media screen and (min-width: 768px)': {
          backgroundColor: 'transparent',
        },

        '&:first-of-type': {
          backgroundColor: `${theme?.background?.utility?.[400]}50`,
          fontWeight: 500,
        },
        '&:nth-of-type(2)': {
          borderBottom: `1px solid ${theme?.border?.secondary}`,

          '@media screen and (min-width: 768px)': {
            backgroundColor: `${theme?.background?.utility?.[400]}25`,
            borderBottom: 'none',
          },
        },
      },
    },

    '.tableContain': {
      ...fullWidth(),
    },
  },
})
