import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentSystem: null
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    changeCurrentSystem: (state, action) => {
      state.currentSystem = action.payload
    },
  },
});

export const selectedCurrentSystem = (state) => state.system.currentSystem;

export const { changeCurrentSystem } = systemSlice.actions

export const systemReducer = systemSlice.reducer;