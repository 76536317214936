/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useTheme } from '@emotion/react'

import { useQuery } from '../../hooks'

import SystemSection from '../system/system-section'
import SystemTray from './system-tray'

import { css } from './css'

function Systems() {
  const [activeSystem, setActiveSystem] = useState(null)

  const theme = useTheme()

  const { data, refetch } = useQuery(`/systems`, { refetchInterval: 10000 })

  const section = (
    <SystemSection
      data={data?.data}
      setActiveSystem={setActiveSystem}
      activeSystem={activeSystem}
      className={activeSystem ? 'tray-open' : ''}
    />
  )

  const tray = (
    <div css={css.tray(theme)} className={activeSystem ? 'open' : null}>
      {activeSystem && (
        <SystemTray
          activeSystem={activeSystem}
          setActiveSystem={setActiveSystem}
          refetchSystem={refetch}
        />
      )}
    </div>
  )

  const getElement = activeSystem
    ? tray
    : section
  
  return (
    <div css={css.systems}>
      {getElement}
    </div>
  )
}

export default Systems