import {
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  marginAuto,
  paddingXY,
} from '../../emotion/utils'

import { appColors } from '../../emotion/_appColors'
import { appFonts } from '../../emotion/_appFonts'

export const css = {
  ...flexbox('column', 'flex-start', 'flex-start', 12),
  ...fullWidth(),
  ...marginAuto(),
  maxWidth: '94%',
  paddingTop: 10,
  paddingBottom: 35,

  btnBack: {
    ...borderRadius(12),
    ...fontbox(appFonts?.inter, 14, 700, 24),
    ...paddingXY(8),
    backgroundColor: appColors?.grays?.[0],
    border: `1px solid ${appColors?.greens?.base}`,
    color: appColors?.greens?.base,
    height: 40,
    minWidth: 128,

    '&:hover': {
      backgroundColor: appColors?.greens?.base,
      color: appColors?.grays?.[0],
    },
  },

  content: {
    ...flexbox('row', 'stretch', 'center', 24, 'wrap'),
    ...fullWidth(),
    ...marginAuto(),
    marginTop: 12,
  },
}
