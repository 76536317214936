/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import SystemCard from './system-card'
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { useDroppable } from '@dnd-kit/core'
import _ from 'lodash'

import Client from '../../client'

import { css } from '../systems/css'
import { useTheme } from '@emotion/react'

const SystemSection = props => {
  const {
    data = [],
    activeSystem,
    setActiveSystem = () => {},
    className = '',
  } = props

  const theme = useTheme()

  const { setNodeRef } = useDroppable({
    id: 'unique-id',
  })
  const [items, setItems] = useState([])

  useEffect(() => {
    if (data && data.length > 0) setItems(data?.map(s => s.id))
  }, [data])

  const sortOrderDifferent = newItems => {
    const oldItems = data?.map(d => {
      return d.id
    })

    return !_.isEqual(oldItems, newItems)
  }
  useEffect(() => {
    if (items && items.length > 0) {
      const data = items.map((item, idx) => {
        return {
          id: item,
          sort_order: idx,
        }
      })
      const putIt = async data => {
        await Client.put('/systems/system-order', { systems: data })
      }
      if (data && sortOrderDifferent) putIt(data)
    }
  }, [items])
  const systemData = data

  function handleDragEnd(event) {
    if (event.active.id !== event.over.id) {
      const oldIdx = items.indexOf(event.active.id)
      const newIdx = items.indexOf(event.over.id)
      const newItems = arrayMove(items, oldIdx, newIdx)
      setItems(newItems)
    }
  }

  const systemsBlock = items?.map(item => {
    const system = systemData.find(system => {
      return system.id === item
    })

    return (
      <SystemCard
        key={system.id}
        data={system}
        id={system.id}
        setActiveSystem={setActiveSystem}
        isActive={activeSystem?.id === system?.id}
      />
    )
  })

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10, // Enable sort function when dragging 10px
    },
  })
  const keyboardSensor = useSensor(KeyboardSensor)
  const sensors = useSensors(mouseSensor, keyboardSensor)

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
      <div
        css={css.cardSection(theme)}
        className={className}
        id="droppable"
        ref={setNodeRef}
      >
        <SortableContext items={items}>{systemsBlock}</SortableContext>
      </div>
    </DndContext>
  )
}
export default SystemSection