/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { useContext } from "react";

import AuthContext from "../../context/auth-context";

import { Icon } from "../../components";

import { appColors } from "../../emotion/_appColors";
import { css } from "./css";

const Menu = (/*{
  activePage,
  setActivePage,
  navOpen,
  setNavOpen,
  hideMenu
}*/) => {

  const { logout } = useContext(AuthContext);

  const theme = useTheme();

  // const topLevelItems = !hideMenu && (
  //   <>
  //     {TOP_LEVEL_PAGES.map((page) => {
  //       return (
  //         <li
  //           key={page.path}
  //           onClick={() => {
  //             setNavOpen(false);
  //             navigate(`/${page.path}`);
  //             setActivePage(page.path);
  //           }}
  //           className={activePage === page.path ? "active" : ""}
  //         >
  //           {page.display}
  //         </li>
  //       );
  //     })}
  //   </>
  // );

  const btnLogout = (
    <li onClick={logout}>
      <Icon icon="Logout" stroke={appColors?.reds?.chileanFire} />
    </li>
  );

  return (
    <nav css={css()}>
      <ul css={css(theme)?.menu}>
        {/* {topLevelItems} */}

        {btnLogout}
      </ul>
    </nav>
  );
}

export default Menu;
