import {
  flexbox,
  fullWidth,
  marginAuto,
  paddingX,
  posFixed,
} from '../../emotion/utils'

const height = [48, 72]

export const css = theme => ({
  ...flexbox('row', 'center', 'space-between', 0),
  ...fullWidth(),
  ...marginAuto(),
  ...posFixed(0, 0, null, 0, 100),
  backgroundColor: theme?.background?.primary,
  height: height[0],
  maxWidth: '92%',

  '@media screen and (min-width: 992px)': {
    height: height[1],
  },
  '@media screen and (min-width: 1200px)': {
    maxWidth: '86%',
    ...paddingX(32),
  },

  '&::before': {
    ...posFixed(0, 0, null, 0, -1),
    backgroundColor: theme?.background?.primary,
    content: '""',
    height: height[0],

    '@media screen and (min-width: 992px)': {
      height: height[1],
    },
  },

  '&::after': {
    ...posFixed(height[0], 0, null, 0, -1),
    backgroundColor: theme?.border?.secondary,
    content: '""',
    height: 1,

    '@media screen and (min-width: 992px)': {
      top: height[1],
    },
  },

  '> div': {
    transform: 'scale(0.75)',
    transformOrigin: 'left',

    '@media screen and (min-width: 992px)': {
      transform: 'scale(1)',
    },
  },
})
