import { Button, Descriptions, Modal } from 'antd';
import React, { useMemo } from "react";
import editUserSchema from '../../schemas/editUserSchema';
import { Formik, Form, ErrorMessage } from "formik";
import FormTextInput from "../../components/form-text-input/form-text-input";
import { Dialog } from 'primereact/dialog';
import { useMutation } from '@tanstack/react-query';

import { css } from "./css";
import ModalButtonSet from '../modal-set-button/modal-set-button';
import FormSelect from '../../components/form-select/form-select-simplified';
import { appColors } from '../../emotion/_appColors';

import { useQuery } from '../../hooks';

import Client, { ENDPOINT } from '../../client';

export default function EditUserModal({
  setShowModal = () => {},
  open,
  onSuccess = () => {},
  user
}) {
  const handleCancel = () => {
    setShowModal(false)
  }

  const { mutateAsync: submitRequest } = useMutation({
    mutationKey: 'add-user',
    networkMode: 'always',
    mutationFn: async data => Client.put(`/users/${user.id}`, data),
    onSuccess: values => {
      setShowModal(false)
      onSuccess(values.body)
    },
  });

  // const horsesUri = useMemo(() => {
  //   const url = new URL("/horses?orderBy=name", ENDPOINT);
  //   return url.toString().replace(ENDPOINT, "");
  // }, []);

  // // get horses
  // const { data: horsesData, refetch: refetchHorses } = useQuery(
  //   horsesUri
  // );

  const initialValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
    //assign_horse: user.horses.map(h => h.id)
  };

  // const horses = horsesData?.data.map((h) => ({
  //   label: h.name,
  //   value: h.id
  // }));
  
  return (
    <>
      {/* <Modal title="Edit a User" open={isModalOpen} footer={null}> */}
      <Dialog
        header={
          <span css={css.dialogHeader} className="dialog-header">
            Edit a User
          </span>
        }
        visible={true}
        style={{ width: 400 }}
        onHide={() => setShowModal(false)}
      >
        <Formik
            initialValues={initialValues}
            onSubmit={submitRequest}
            validationSchema={editUserSchema}
            enabledReinitialize
          >
            {(formik) => (
              <Form>
                <FormTextInput
                  formik={formik}
                  name="email"
                  label="Email"
                  labelPosition="top"
                  //readOnly
                />
                {formik.touched.email && formik.errors.email && <div id="feedback">{formik.errors.email}</div>}

                <FormTextInput
                  formik={formik}
                  name="first_name"
                  label="First Name"
                  labelPosition="top"
                />
                {formik.touched.first_name && formik.errors.first_name && <div id="feedback">{formik.errors.first_name}</div>}

                <FormTextInput
                  formik={formik}
                  name="last_name"
                  label="Last Name"
                  labelPosition="top"
                />
                {formik.touched.last_name && formik.errors.last_name && <div id="feedback">{formik.errors.last_name}</div>}

                <FormTextInput
                  formik={formik}
                  name="phone"
                  label="Phone"
                  labelPosition="top"
                />
                {formik.touched.phone && formik.errors.phone && <div id="feedback">{formik.errors.phone}</div>}

                {/* <FormSelect
                  formik={formik}
                  name="assign_horse"
                  label="Assign Horse(s)"
                  placeholder="Select All That Apply"
                  options={horses}
                  isMulti={true}
                  onBlur={formik.handleBlur}
                /> */}

                <ModalButtonSet
                  onCancel={handleCancel}
                  // isSubmitting={formik.isSubmitting}
                  onSubmit={formik.handleSubmit}
                  disabled={!formik.dirty}
                  submitButtonText="Save changes"
                  submitButtonColor={appColors?.greens?.base}
                />
              </Form>
            )}
        </Formik>
      </Dialog>
    </>
  );
}