import React from 'react'
import { Route } from 'react-router-dom'

import Login from '../pages/login/login'
import AcceptInvite from '../pages/accept-invite/accept-invite'
// import ForgotPassword from '../pages/forgot-password/forgot-password'
import ResetPassword from '../pages/reset-password/reset-password'

import UnProtectedLayout from '../layouts/unprotected'

const LoginRouter = (
  <Route element={<UnProtectedLayout />}>
    <Route path="/login" element={<Login />} />
    <Route path="/accept-invite" element={<AcceptInvite />} />
    <Route path="/reset-password" element={<ResetPassword />} />
    {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
  </Route>
)

export default LoginRouter
