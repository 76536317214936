/** @jsxImportSource @emotion/react */

import { InputText } from "primereact/inputtext";

import { cssFormElements } from "../css";

const Input = (data) => {
  const getLabel = data?.label && <label>{data.label}</label>;

  const allProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
  };

  const elProps = {
    // Component based off of PrimeReact InputText component
    // https://primereact.org/inputtext/

    // required props for PrimeReact InputText component
    value: allProps?.value,
    onChange: allProps?.onChange,

    // optional props
    invalid: allProps?.invalid,
    keyfilter: allProps?.keyfilter,
    placeholder: allProps?.placeholder,
    pt: allProps?.pt,
    ptOptions: allProps?.ptOptions,
    size: allProps?.size,
    tooltip: allProps?.tooltip,
    tooltipOptions: allProps?.tooltipOptions,
    type: allProps?.type,
    unstyled: allProps?.unstyled,
    validateOnly: allProps?.validateOnly,

    // custom props
    id: allProps?.id,
    name: allProps?.name,
    onBlur: allProps?.onBlur,
  };

  const getElement = <InputText {...elProps} />;

  return (
    <div css={cssFormElements}>
      {getLabel}
      {getElement}
    </div>
  );
};

export default Input;
