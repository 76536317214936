import {
  flexbox,
  fontbox,
  fullWidth,
  square,
} from '../../emotion/utils';

import { appFonts } from '../../emotion/_appFonts';

export const css = theme => ({
  ...flexbox('column', 'flex-start', 'flex-start', 12),
  paddingBottom: 32,

  '@media screen and (min-width: 768px)': {
    gap: 24,
  },
  
  '> *': {
    ...fullWidth(),
  },

  actionBlock: {
    ...flexbox('row', 'center', 'space-between', 12),

    '> div': {
      cursor: 'pointer',
    },

    back: {
      ...flexbox('row', 'center', 'flex-start', 6),
      ...fontbox(appFonts?.inter, 12, 600, 20),
      color: theme?.text?.primary?.[600],

      '@media screen and (min-width: 768px)': {
        ...fontbox(appFonts?.inter, 14, 600, 20),
      },

      '&:hover': {
        color: theme?.text?.utility?.[400],

        'svg': {
          'path': {
            stroke: theme?.text?.utility?.[400],
          }
        }
      },
    },

    expand: {
      ...square(20),

      '&:hover': {
        'svg': {
          'path': {
            fill: theme?.text?.utility?.[400],
          }
        }
      },
    },
  },

  content: {
    ...flexbox('column', 'flex-start', 'flex-start', 12),
    ...fullWidth(),

    '@media screen and (min-width: 768px)': {
      ...flexbox('row', 'stretch', 'flex-start', 24),
    },
  },
  
  videoBlock: {
    ...flexbox("column", "flex-start", "flex-start", 8),
    ...fullWidth(),

    '@media screen and (min-width: 768px)': {
      maxWidth: 350,
    },
    '@media screen and (min-width: 992px)': {
      maxWidth: 460,
    },
    '@media screen and (min-width: 1100px)': {
      maxWidth: 610,
    },
  },

  statusBlock: {
    ...fullWidth(),
    flex: 1,

    '> div': {
      '> div': {
        ...flexbox('column', 'flex-start', 'flex-start', 0),

        '> *': {
          ...fullWidth(),
        },
      }
    },
  },
})
