/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Header } from "../../components";

import { css } from "./css";

const Main = () => {
  const loc = useLocation();
  const [activePage, setActivePage] = useState(loc.pathname.slice(1));
  console.log('active page..', activePage);

  const theme = useTheme();

  return (
    <div className="site">
      <Header activePage={activePage} setActivePage={setActivePage} />
      
      <main css={css(theme)}>
        <Outlet context={[activePage, setActivePage]} />
      </main>
    </div>
  );
};

export default Main;