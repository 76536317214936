import React from "react";

const IconGo = ({ fill = "#DBDBDB" }) => {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10">
      <path
        d="M5.83709 4.6098L1.29221 0.159755C1.1871 0.0567475 1.04677 0 0.897152 0C0.74753 0 0.607207 0.0567475 0.50209 0.159755L0.167391 0.487394C-0.0503999 0.700889 -0.0503999 1.04788 0.167391 1.26105L3.98384 4.99793L0.163156 8.73895C0.0580388 8.84196 0 8.97928 0 9.1257C0 9.27228 0.0580388 9.4096 0.163156 9.51269L0.497855 9.84025C0.603055 9.94325 0.743295 10 0.892917 10C1.04254 10 1.18286 9.94325 1.28798 9.84025L5.83709 5.38614C5.94246 5.2828 6.00033 5.14484 6 4.99817C6.00033 4.85094 5.94246 4.71305 5.83709 4.6098Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconGo;
