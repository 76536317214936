import { useContext } from "react";
import { useQuery as useReactQuery } from "@tanstack/react-query";

import { useToast } from "../hooks";
import Client from "../client";

import AuthContext from "../context/auth-context";

export const useQuery = (endpoint, options) => {
  const { toast } = useToast();
  const {
    networkMode = "online",
    retry = 0,
    onSuccess,
    onError,
    enabled = true, // Add enabled option
    refetchInterval,
    cacheTime,
  } = options || {};
  const queryKey = [endpoint];
  const queryFn = () => Client.get(endpoint);

  const auth = useContext(AuthContext);

  const authEnabled = auth?.enabled === false ? false : true;

  return useReactQuery({
    queryKey,
    queryFn,
    networkMode,
    retry,
    onSuccess,
    cacheTime,
    refetchInterval,
    onError: (error) => {
      toast({
        type: "error",
        message: error.message,
      });
      onError && onError(error);
    },
    enabled: enabled && authEnabled,
  });
};
