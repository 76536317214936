/** @jsxImportSource @emotion/react */

import { Icon } from "../index";
import { Link } from "react-router-dom";
import { css } from "./css";

const FullLogo = () => (
  <Link to="/">
    <div css={css}>
      <Icon icon="LogoIcon" />
      <Icon icon="LogoType" />
    </div>
  </Link>
);

export default FullLogo;
