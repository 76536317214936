/** @jsxImportSource @emotion/react */

import { useState, useEffect } from "react";
import { useTheme } from "@emotion/react";

import { VideoPlayer } from '../index';

import { css as tempCss } from "./css";

export default function VideoComponent(props) {
  const {
    currentCamera,
    full_screen = false
  } = props;

  const theme = useTheme()
  const css = tempCss(theme)

//  const [cameraToShow, setCameraToShow] = useState({});

  const initStatus = "Real times snapshot, loading camera stream";

  const [status, setStatus] = useState(initStatus);
  const [wsUrl, setWsUrl] = useState(null);


  // useEffect(() => {
  //   setCameraToShow(currentCamera);
  // }, [currentCamera]);

  useEffect(() => {
    if (currentCamera && currentCamera.id) {
      const wsUrl = window.location.hostname === 'localhost'
      ? 'ws://localhost:3001'
      : `wss://${window.location.host}`
      const currentCameraLink = `${wsUrl}/api/camera/${currentCamera?.id}`;
      setWsUrl(currentCameraLink);
    }
  }, [currentCamera]);


  return (
    <div css={css.videocard}>
      <VideoPlayer
        url={currentCamera?.id ? `${wsUrl}` : null} 
        status={status}
        setStatus={setStatus}
        fullScreen={full_screen}
        //id={currentCamera?.id}
      />
      <p>{status}</p>
    </div>
  );
}