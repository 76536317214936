import { appColors } from "./_appColors";
import { appFonts } from "./_appFonts";

export const appThemes = (thm) => {
  const theme = {
    light: {
      background: {
        primary: appColors?.grays?.[0],
        disabled: appColors?.blues?.flashWhite,
        success: appColors?.greens?.jade,

        coral: {
          50: appColors?.coral?.[50], 
        },

        gray: {
          100: appColors?.grays?.[100],
          700: appColors?.grays?.[740],
        },

        utility: {
          400: appColors?.reds?.chileanFire,
        },
      },

      border: {
        secondary: appColors?.blues?.solitude,

        coral: {
          300: appColors?.coral?.[300],
        },
      },

      button: {
        primary: {
          background: appColors?.reds?.chileanFire,
          border: `${appColors?.grays?.[0]}12`,
          color: appColors?.grays?.[0],
        },

        tertiary: {
          background: appColors?.blues?.eastBay,
        },
      },

      text: {
        grays: {
          0: appColors?.grays?.[0],
          1: appColors?.grays?.[1],
        },

        blues: {
          tertiary: appColors?.blues?.eastBay,
        },

        primary: {
          600: appColors?.blues?.eastBay,
          900: appColors?.blues?.blackRussian,
        },

        utility: {
          400: appColors?.reds?.chileanFire,
        },
      },

      table: {
        background: appColors?.grays?.[0],
        border: appColors?.coral?.[300],

        th: {
          background: appColors?.coral?.[50],
        },
      },
    },

    dark: this?.light,
    // dark: {
    //   background: {
    //     primary: appColors?.grays?.[0],
    //     disabled: appColors?.blues?.flashWhite,
    //   },

    //   border: {
    //     secondary: appColors?.blues?.solitude,
    //   },

    //   button: {
    //     tertiary: {
    //       background: appColors?.blues?.eastBay,
    //     },
    //   },

    //   text: {
    //     primary: {
    //       600: appColors?.blues?.eastBay,
    //       900: appColors?.blues?.blackRussian
    //     }
    //   }
    // },
  };

  return theme[thm];
};

export const bp = [576, 768, 992, 1200];

// FUNCTIONS ---------------------------------------------

// export const darken = (color, percent) => {
//   const f = parseInt(color.slice(1), 16),
//     t = percent < 0 ? 0 : 255,
//     p = percent < 0 ? percent * -1 : percent,
//     R = f >> 16,
//     G = (f >> 8) & 0x00ff,
//     B = f & 0x0000ff;

//   return (
//     "#" +
//     (
//       0x1000000 +
//       (Math.round((t - R) * p) + R) * 0x10000 +
//       (Math.round((t - G) * p) + G) * 0x100 +
//       (Math.round((t - B) * p) + B)
//     )
//       .toString(16)
//       .slice(1)
//   );
// };

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// MIXINS ------------------------------------------------

// Border Radius
export const borderRadius = (rad) => {
  const borderRadius = rad || 0;

  return { borderRadius };
};
export const borderTopLeftRadius = (rad) => {
  const borderTopLeftRadius = rad || 0;

  return { borderTopLeftRadius };
};
export const borderTopRightRadius = (rad) => {
  const borderTopRightRadius = rad || 0;

  return { borderTopRightRadius };
};
export const borderBottomLeftRadius = (rad) => {
  const borderBottomLeftRadius = rad || 0;

  return { borderBottomLeftRadius };
};
export const borderBottomRightRadius = (rad) => {
  const borderBottomRightRadius = rad || 0;

  return { borderBottomRightRadius };
};

// Dimensions
export const dimensions = (w, h) => {
  const width = w || null;
  const height = h || null;

  return { width, height };
};

// Flexbox
export const flexbox = (fd, ai, jc, gp, fw) => {
  const alignItems = ai === undefined || ai === null ? "center" : ai;
  const display = "flex";
  const flexDirection = fd === undefined || fd === "column" ? "column" : fd;
  const gap = gp === undefined || gp === null ? 0 : gp;
  const justifyContent = jc === undefined || jc === null ? "center" : jc;
  const flexWrap = fw === undefined || fw === null ? null : fw;

  return {
    alignItems,
    display,
    flexDirection,
    flexWrap,
    gap,
    justifyContent,
  };
};

// Fontbox
export const fontbox = (ff, fs, fw, lh) => {
  const fontFamily = ff === undefined || ff === null ? appFonts?.inter : ff;
  const fontSize = fs === undefined || fs === null ? 14 : fs;
  const fontWeight = fw === undefined || fw === null ? "400" : `${fw}`;
  const lineHeight =
    lh === undefined || lh === null ? `${fs + 2}px` : `${lh}px`;

  return { fontFamily, fontSize, fontWeight, lineHeight };
};

// Full Height
export const fullHeight = () => {
  const height = "100%";

  return { height };
};

// Full Width
export const fullWidth = () => {
  const width = "100%";

  return { width };
};

// Gridbox
export const gridbox = (gtc, gp) => {
  const display = "grid";
  const gridTemplateColumns = `repeat(${gtc}, 1fr)`;
  const gap = gp === undefined || gp === null ? 0 : gp;

  return {
    display,
    gridTemplateColumns,
    gap,
  };
};

// Margin
export const margin = (t, r, b, l) => {
  const marginTop = t || null;
  const marginRight = r || null;
  const marginBottom = b || null;
  const marginLeft = l || null;

  return { marginTop, marginRight, marginBottom, marginLeft };
};
export const marginY = (num) => {
  const marginTop = num !== undefined ? num : null;
  const marginBottom = num !== undefined ? num : null;

  return { marginTop, marginBottom };
};
export const marginX = (num) => {
  const marginRight = num !== undefined ? num : null;
  const marginLeft = num !== undefined ? num : null;

  return { marginRight, marginLeft };
};

// Margin Auto
export const marginAuto = () => {
  const margin = "0 auto";

  return { margin };
};

// Object Contain
export const objectContain = () => {
  const objectFit = "contain";
  const maxHeight = "100%";
  const maxWidth = "100%";

  return { objectFit, maxHeight, maxWidth };
};

// Padding
export const padding = (t, r, b, l) => {
  const paddingTop = t !== undefined ? t : null;
  const paddingRight = r !== undefined ? r : null;
  const paddingBottom = b !== undefined ? b : null;
  const paddingLeft = l !== undefined ? l : null;

  return { paddingTop, paddingRight, paddingBottom, paddingLeft };
};
export const paddingY = (num) => {
  const paddingTop = num !== undefined ? num : null;
  const paddingBottom = num !== undefined ? num : null;

  return { paddingTop, paddingBottom };
};
export const paddingX = (num) => {
  const paddingRight = num !== undefined ? num : null;
  const paddingLeft = num !== undefined ? num : null;

  return { paddingRight, paddingLeft };
};
export const paddingXY = (num) => {
  const padding = num !== undefined ? num : null;

  return { padding };
};

export const position = (pos, topPos, rightPos, bottomPos, leftPos, z) => {
  const position = pos !== undefined ? pos : "relative";
  const top = topPos !== undefined ? topPos : null;
  const right = rightPos !== undefined ? rightPos : null;
  const bottom = bottomPos !== undefined ? bottomPos : null;
  const left = leftPos !== undefined ? leftPos : null;
  const zIndex = z !== undefined ? z : null;

  return { position, top, right, bottom, left, zIndex };
};
// Alias functions for position
export const posAbsolute = (topPos, rightPos, bottomPos, leftPos, z) => {
  return position("absolute", topPos, rightPos, bottomPos, leftPos, z);
};
export const posRelative = (topPos, rightPos, bottomPos, leftPos, z) => {
  return position("relative", topPos, rightPos, bottomPos, leftPos, z);
};
export const posFixed = (topPos, rightPos, bottomPos, leftPos, z) => {
  return position("fixed", topPos, rightPos, bottomPos, leftPos, z);
};
export const posSticky = (topPos, rightPos, bottomPos, leftPos, z) => {
  return position("sticky", topPos, rightPos, bottomPos, leftPos, z);
};
export const posStatic = () => {
  return position("static");
};

export const square = (size) => {
  const width = size ? size : null;
  const height = size ? size : null;

  return { width, height };
};

export const transition = (property = "all", seconds = "0.3") => {
  const transition = `${property} ${seconds}s ease`;

  return { transition };
};
