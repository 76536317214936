import { ReactComponent as ChevronLeft } from "./assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "./assets/chevron-right.svg";
import { ReactComponent as Edit } from "./assets/edit.svg";
import { ReactComponent as Expand } from "./assets/expand.svg";
import { ReactComponent as Horse } from "./assets/horse.svg";
import { ReactComponent as LogoIcon } from "./assets/logo-icon.svg";
import { ReactComponent as LogoType } from "./assets/logo-type.svg";
import { ReactComponent as Logout } from "./assets/logout.svg";
import { ReactComponent as Settings } from "./assets/settings.svg";
import { ReactComponent as Trash } from "./assets/trash.svg";
import { ReactComponent as User } from "./assets/user.svg";
import { ReactComponent as Video } from "./assets/video.svg";
import { ReactComponent as X } from "./assets/x.svg";
import { ReactComponent as CameraFeedDefault } from "./assets/camera-default-feed.svg";

export const icons = {
  ChevronLeft,
  ChevronRight,
  Edit,
  Expand,
  Horse,
  LogoIcon,
  LogoType,
  Logout,
  Settings,
  Trash,
  User,
  Video,
  X,
  CameraFeedDefault,
};
