/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { useState } from "react";

import {
  FullLogo,
  Menu
} from "../../components";

import { css } from "./css";

//import css from "./header.module.scss";
// import MobTrigger from "./header-trigger";

export default function Header({
  activePage,
  setActivePage,
  hideMenu = false,
}) {
  const [navOpen, setNavOpen] = useState(false);

  const theme = useTheme();

  const logo = <FullLogo />;

  return (
    <header css={css(theme)}>
      {/* <MobTrigger navOpen={navOpen} setNavOpen={setNavOpen} /> */}

      {logo}

      <Menu
        activePage={activePage}
        setActivePage={setActivePage}
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        hideMenu={hideMenu}
      />
    </header>
  );
}
