import { paddingX } from "../../emotion/utils";

export const css = theme => ({
  ...paddingX('3%'),
  paddingTop: 56,
  paddingBottom: 32,

  '@media screen and (min-width: 576px)': {
    paddingTop: 76,
  },
  '@media screen and (min-width: 992px)': {
    paddingTop: 96,
  },
});
