import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  number_of_systems: "",
  total_number_of_systems: ""
};

export const barnSetupSlice = createSlice({
  name: 'barnSetup',
  initialState,
  reducers: {
    changeNumberOfSystems: (state, action) => {
      state.number_of_systems = action.payload;
    },
    changeTotalNumberOfSystems: (state, action) => {
      state.total_number_of_systems = action.payload;
    }
  }
});

export const { changeNumberOfSystems, changeTotalNumberOfSystems } = barnSetupSlice.actions

export const barnSetupReducer = barnSetupSlice.reducer;